<template>
<Toast position="top-right" />
<div class="p-grid d-p-flex p-jc-center" style="">
  <div class="" style="max-width:600px; width:100%; margin:auto; margin-top: 80px;">
    <div class="text-title p-fluid p-text-center" style="margin: 10px 0px 20px 0px;">
      <h2 class="p-mb-0" style="color:#424242; font-weight: 500;">Acceso</h2>
    </div>
    <div class="card p-shadow p-radius p-pt-5 p-pb-3" style="width:100%;">
      <form @submit.prevent="vericicar_acceso">
        <div class="contenedor p-px-2 p-pb-3 p-d-flex p-jc-around">
          <div class="contenedor-imagen p-d-flex p-fluid p-d-flex p-ai-center p-jc-center">
            <img src="assets/layout/images/logo_login.png" style="height:200px;" />
          </div>
          <div class="contenedor-form flex" style="width:320px; padding: 0px 0px 0px 20px;">
            <div class="p-fluid">
              <div class="p-field p-fluid">
                <span style="font-size: 12px; font-weight: 500; color: #424242;">Usuario</span>
                <span class="p-input-icon-right">
                  <InputText id="firstname" v-model="f_login.usuario" type="text" placeholder="Correo, Carnet o Usuario" />
                  <i class="pi pi-user" />
                </span>
                <small class="text-danger" v-bind:class="{ 'p-hidden' : user_is_valid }">Debe ingresar el usuario.</small>
              </div>
              <div class="p-field p-fluid">
                <div class="p-mb-2">
                  <span style="font-size: 12px; font-weight: 500; color: #424242;">Clave</span>
                  <Password  id="password" toggleMask v-model="f_login.clave" :feedback="false" placeholder="Contraseña" />
                  <small class="text-danger" v-bind:class="{ 'p-hidden' : pass_is_valid }">Debe ingresar la contraseña.</small>
                </div>
                <div class="p-col p-p-0 p-mt-2 p-mb-3 p-d-flex p-jc-between p-ai-center">
                  <router-link to="/recuperacion"><span style="color:gray; text-decoration: underline !important;">¿Olvido la clave?</span></router-link>
                  <div class="p-field p-d-flex p-jc-between p-ai-center p-m-0">
                    <div class="p-field-checkbox p-m-0">
                      <Checkbox id="remember" class="p-checkbox-remember" :binary="true" value="1" @change="setRemember" v-model="remember" />
                      <label for="remember" style="font-size: 10pt;">Recordar</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-fluid p-text-center">
              <Button :loading="loading" type="submit" label="Iniciar sesión" icon="pi pi-sign-in" @click="vericicar_acceso" class="p-d-block" />
            </div>
            <div class="p-fluid">
              <Message  v-bind:class="{ 'p-hidden' : access_ok }" severity="error" :closable="false">Las credenciales son incorrectas</Message>
            </div>
            <Divider align="center" class="p-mb-1">
              <span class="text-muted">¿Aún no está registrado?</span>
            </Divider>
            <div class="p-fluid p-mt-2">
              <div class="col p-p-0 p-text-center" style="font-size:15px !important;">
                <router-link to="/register">Registrese aquí</router-link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
      <Message v-if="registered" @close="$store.commit('setRegistered', '0')" severity="success">¡Se ha registrado exitosamente!</Message>
  </div>
</div>
</template>

<style>

  .p-field-checkbox .p-checkbox-remember{
    width: 15px;
    height: 15px;
  }
  .p-checkbox-remember{
    margin: 0;
  }
  .p-checkbox-remember .p-checkbox-box{
    width:15px;
    height: 15px;
  }

  .contenedor{
    display: flex;
    width: 100% !important;
  }
  .contenedor-imagen{
    width:280px; 
    border-right: 1px solid #DEE2E6;
  }
  @media screen and (max-width: 600px) {
    .text-title{
      margin: 20px 0px 0px 0px !important;
    }
    .contenedor{
      display: flex;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      width: 100% !important;
    }
    .contenedor-imagen{
      border: none !important;
      width: 100px !important;
    }
    .contenedor-form{
      width: 100% !important;
      padding: 10px 20px !important;
    }
  }
</style>

<script>
export default {
  data : () => {
    return {
      q : '',
      tipo_form : 0,
      remember : "",
      loading : false,
      f_login : {
        usuario : '',
        clave : ''
      },
      user_is_valid : true,
      pass_is_valid : true,
      access_ok : true,
      result : []
    }
  },
  mounted(){
    this.remember = this.$store.state.remember;
    this.menu = this.$store.state.remember;
    this.f_login.usuario = this.$store.state.login_username;
    this.$store.commit("setLogged",0);
  },
  methods : {
    setRemember(){
      this.$store.commit("setRemember", this.remember);
      if(!this.remember) this.$store.commit("setUsername", "")
    },
    async vericicar_acceso(){
      this.user_is_valid = this.f_login.usuario.length == 0 ? false : true;
      this.pass_is_valid = this.f_login.clave.length == 0 ? false : true;
      if(!this.user_is_valid || !this.pass_is_valid) return false;
      this.loading = true;
      this.access_ok = true;
      await this.axios.post("/Login", this.f_login)
      .then(r => { 
        this.result = r.data 
        if(r.data == 0){
            this.access_ok = false    
        }else{
          if(this.remember) {
            this.$store.commit("setUsername", this.f_login.usuario)
          }
          this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + r.data;
          
          this.$store.commit('setMenu', 
            [
              {label: 'Cursos', icon: 'pi pi-bookmark', to: '/cursos'},
              {label: 'Mi actividad', icon: 'pi pi-check-circle', to: '/actividad'},
              {label: 'Pagos', icon: 'pi pi-link', to: '/pagos'}
            ]
          );
          this.$store.commit('setTipo', r.data.tipo);
          this.$store.commit("setToken", r.data.token);
          this.$store.commit("setNombre", r.data.nombre);
          this.$store.commit("setCodigoUonline", r.data.codigo_uonline);
          this.$store.commit("setCodigoCliente", r.data.codigo_cliente);
          this.$store.commit("setLogged", 1);
          this.$store.commit('setRegistered', '0');
          this.$router.push("/cursos");
        }
      })
      .catch(() => {  })
      .then(() => { this.loading = false })
      if(this.result.length == 0 || this.result == 0){
        this.access_ok = false;
      }
    },
  },
  computed : {
    registered : function(){
      return this.$store.state.is_registered == '1' ? true : false;
    }
  }
}
</script>

<style scoped>

</style>
